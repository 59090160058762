<template>
    <div class="not-found-container d-flex align-items-center justify-content-center flex-column h-100">
        <img src="/assets/imgs/logo.png" class="img-fluid" alt="Convo4u Logo" width="150">
        <h2 class="m-0 my-3">Página não encontrada</h2>
        <p class="text-dark mb-3">
            Aconteceu alguma coisa inesperada, a página que você está tentando acessar não existe. 
            Você pode tentar voltar para a página anterior ou utilizar os botões de navegação para ir a outra página.
        </p>
        <button @click="$router.back()" class="btn btn-outline-primary">
            Voltar
        </button>
    </div>
</template>

<script>
export default {
    name: "Error404"
}
</script>